// src/pages/Home.js
import React, { useEffect, useRef, useState } from "react"; // Add useEffect here
import { ReelParthner } from "../components/ReelParthner";
import DesignIdeas from "../components/DesignIdeas";
import AOS from "aos";
import "aos/dist/aos.css";
import CompanyNumbers from "../components/CompanyNumber";
import ClientFeedback from "../components/ClientFeedback";
import BookNow from "../components/BookNow";
import Blogs from "../components/Blogs";
import { ContactForm } from "../components/ContactForm";
import HomeBannerSlider from "../components/HomeBannerSlider";
import Awards from "../components/Awards";

import Loader from "../components/Loader";
const Home = () => {

  const [loading, setLoading] = useState(true); // Add loading state
  const contactFormRef = useRef(null); // Create a ref for ContactForm


  const handleScrollToContact = () => {
    contactFormRef.current?.scrollIntoView({ behavior: "smooth" }); // Scroll to ContactForm
  };

  useEffect(() => {
    AOS.init({ duration: 1000 });
    // Simulate a short loading time (e.g., 1 second) before hiding the loader
    setTimeout(() => setLoading(false), 1000);
  }, []);

  if (loading) return <Loader />; // Display loader until loading is false
  return (
    <>
      <div className="Home-banner">
         <HomeBannerSlider/>
        <div className="container" data-aos="fade-up">
          <h1 className="pulse-animation">
            Let’s design your
            <p>
              Dream home with <span>Modular Kitchen.</span>
            </p>
          </h1>
          <button className="btn btn-primary" onClick={handleScrollToContact}>Get Free Estimate</button>
        </div>
      </div>

      <div className="reelsection py-3">
        <ReelParthner />
      </div>

      <section className="about-section">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-6 col-xl-5 heading-main mb-0 mb-md-4">
              <span className="badge rounded-pill text-bg-warning">
                About Us
              </span>
              <h2>We are the perfect team to bring your home kitchen decor dreams to life!</h2>
              <h3>
              We design experiences and craft products that drive business growth.
              </h3>
              <p>
               Receive expert guidance from modular kitchen, a seasoned business coach specializing in growth and scaling strategies.
              </p>

              <ul className="ulservices">
                <li>
                  <div className="uiInnner">
                    <strong>
                      <img src="images/Star.svg" alt="Business Services" />
                      100+
                    </strong>
                    <span>Interior Services</span>
                  </div>
                </li>
                <li>
                  <div className="uiInnner">
                    <strong>
                      <img src="images/happy.svg" alt="Happy Customer" />
                      800+
                    </strong>
                    <span>Happy Customer</span>
                  </div>
                </li>
              </ul>
            </div>

            <div className="col-lg-6">
              <img src="images/aboutus.png" alt="about image" />
            </div>
          </div>
        </div>
      </section>

      <section className="designIdeasSection">
        <div className="container">
          <div className="heading-main mb-5">
            <span className="badge rounded-pill text-bg-warning">
              Design Ideas
            </span>
            <h2 className="d-flex align-items-end">
            Discover inspiring ideas for your <br/>dream home kitchen designs!
              {/* <button className="btn btn-primary ms-auto">More Designs</button> */}
            </h2>
          </div>
          <DesignIdeas />
        </div>
      </section>

      <section className="services-section">
        <div className="container">
          <div className="heading-main mb-5 text-center">
            <span className="badge rounded-pill text-bg-warning">Services</span>
            <h2>6 Steps Of Completion Interior</h2>
          </div>
          <div className="row">
            <div className="col-md-4 mb-4">
              <div className="card-steps">
                <img src="images/step-1.png" alt="Book a Free Appointment" />
                <h3>Book a Free Appointment</h3>
                <p>
                Let’s create your dream Kitchen with a stunning Modular Kitchen
                </p>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card-steps">
                <img src="images/step-1.png" alt="Book a Free Appointment" />
                <h3>Seal The Deal</h3>
                <p>
                We are the perfect team to bring your home kitchen decor dreams to life!
                </p>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card-steps">
                <img src="images/step-1.png" alt="Book a Free Appointment" />
                <h3>Place your order</h3>
                <p>
                We design experiences and craft products that drive business growth.

                </p>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card-steps">
                <img src="images/step-1.png" alt="Book a Free Appointment" />
                <h3>Get ready for installation</h3>
                <p>
                Receive expert guidance from Alex Moore, a seasoned business coach specializing in growth and scaling strategies.

                </p>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card-steps">
                <img src="images/step-1.png" alt="Book a Free Appointment" />
                <h3>The Final Payment</h3>
                <p>
                Discover inspiring ideas for your dream home kitchen designs!

                </p>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card-steps">
                <img src="images/step-1.png" alt="Book a Free Appointment" />
                <h3>All Set To Move In</h3>
                <p>
                The fastest-growing modular kitchen brand in Jaipur!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="OurprojectSection">
        <div className="container">
          <div className="heading-main mb-5">
            <span className="badge rounded-pill text-bg-warning">
              Our Projects
            </span>
            <h2 className="d-flex align-items-end">
              {" "}
              The fastest-growing modular  <br />
              kitchen brand in Jaipur!
              {/* <button className="btn btn-primary ms-auto">More Gallery</button> */}
            </h2>
          </div>

          <div className="row outer-gallery">
            <div className="col-md-6 col-lg-4 mb-4">
              <div className="gallary-box">
                <img src="images/gallery-1.png" alt="Gallery 1" />
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-4">
              <div className="gallary-box">
                <img src="images/gallery-2.png" alt="Gallery 1" />
              </div>
            </div>

            <div className="col-md-6 col-lg-4 mb-4">
              <div className="gallary-box">
                <img src="images/gallery-3.png" alt="Gallery 1" />
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-4">
              <div className="gallary-box">
                <img src="images/gallery-4.png" alt="Gallery 1" />
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-4">
              <div className="gallary-box">
                <img src="images/gallery-5.png" alt="Gallery 1" />
              </div>
            </div>

            <div className="col-md-6 col-lg-4 mb-4">
              <div className="gallary-box">
                <img src="images/gallery-6.png" alt="Gallery 1" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <CompanyNumbers />

      <ClientFeedback />
     
      <BookNow />

      {/* <Blogs /> */}

      <section className="about-section" ref={contactFormRef}>
        <div className="container">
          <div className="row justify-content-between align-items-start">
            <div className="col-lg-6 col-xl-5 heading-main">
              <span className="badge rounded-pill text-bg-warning">
                Proccess
              </span>
              <h2>Get in touch with us – it's easy!</h2>
              <p>
              You won't have to deal with such inconveniences because we have been diligently providing modular kitchen solutions in Jaipur.
              </p>

              <ul className="Contact_process mt-4 pt-2">
                <li>
                  <div className="pro-flex">
                    <img src="images/pro-call.png" />
                    <p>
                      <span>Contact Number</span>
                      <strong>+91 78782 41849</strong>
                    </p>
                  </div>
                </li>

                <li>
                  <div className="pro-flex">
                    <img src="images/pro-email.png" />
                    <p>
                      <span>Official Email ID</span>
                      <strong>sales.modularkitchens@gmail.com</strong>
                    </p>
                  </div>
                </li>

                <li>
                  <div className="pro-flex">
                    <img src="images/pro-location.png" />
                    <p>
                      <span>Office Address</span>
                      <strong>
                      Metro Piller No 35, near Ganga Jamuna Petrol Pump, Gangaram Nagar, New Aatish Market, Shanthi Nagar, Mansarovar, Jaipur, Rajasthan 302020
                      </strong>
                    </p>
                  </div>
                </li>
              </ul>
            </div>

            <div className="col-lg-6">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>

  <section className="seoText">
      <div className="container">
          <h2>Why Choose Our Modular Kitchens in Jaipur?</h2>
        <p>Whether you're dreaming of a luxurious modular kitchen or a functional, budget-friendly setup, our team of experts provides custom modular kitchen solutions that bring your vision to life. With years of experience in modular kitchen installation and remodeling, we deliver kitchens that maximize space, ensure durability, and add elegance to your home.</p>
        <h3>Our Modular Kitchen Services in Jaipur</h3>
        <h4>1. Modular Kitchen Design & Installation:</h4>
        <p>From modern kitchen designs to classic styles, our design team creates customized layouts that fit your space and style. We ensure each element—from cabinetry to counters—is thoughtfully planned and installed for optimal function and beauty.</p>
        <h4>2. Affordable & Luxury Modular Kitchens:</h4>
        <p>We believe everyone deserves a beautiful kitchen. That’s why we offer affordable modular kitchens without compromising on quality and a range of luxury modular kitchens for those looking to invest in premium materials and finishes.</p>
        <h4>3. Kitchen Renovation & Remodeling:</h4>
        <p>Already have a kitchen that needs a facelift? Our kitchen renovation and kitchen remodeling services in Jaipur breathe new life into old spaces. We handle every detail, from design updates to full kitchen transformations</p>
        <h4>4. Custom Kitchen Cabinets:</h4>
        <p>Cabinets are the backbone of any modular kitchen. Our kitchen cabinets in Jaipur are tailored to suit your needs, with a wide range of finishes, materials, and storage options. Built to last, these cabinets ensure that your kitchen remains clutter-free and organized.</p>

        <h3>Explore Our Modular Kitchen Showrooms in Jaipur</h3>
        <p>For those interested in exploring options, visit our modular kitchen showrooms in Jaipur. Here, you can experience our designs firsthand, talk to our experts, and discuss how we can customize a kitchen that fits your unique needs.</p>

        <h3>Why Modular Kitchens are Perfect for Jaipur Homes</h3>
        <ul>
            <li><b>●	Efficient Use of Space:</b> With smart layouts and storage solutions, modular kitchens make the best use of space in compact city apartments and large homes alike.</li>
            <li><b>●	Stylish and Modern Designs:</b> Choose from contemporary designs that match your home’s aesthetic, with a variety of colors, materials, and finishes.</li>
            <li><b>●	Ease of Maintenance:</b> Designed for easy cleaning and maintenance, modular kitchens in Jaipur are built to last, making them a practical choice.</li>
            <li><b>●	Quick Installation:</b> Modular kitchens are quicker to install than traditional kitchens, allowing you to enjoy your new cooking space sooner.</li>
        </ul>
        <h3>Our Process</h3>
        <p>Our modular kitchen process in Jaipur is simple, efficient, and transparent:</p>
        <ul>
          <li><b>1.	Consultation & Design:</b> We start by understanding your vision and space requirements. Our design team then creates a plan tailored specifically to your home.</li>
        <li><b>2.	Material Selection & Customization:</b> Choose from a wide variety of materials, colors, and finishes to personalize every aspect of your kitchen.</li>
        <li><b>3.	Installation:</b> Our expert team ensures fast and professional installation with minimal disruption to your daily routine.</li>
        <li><b>4.	Final Touches & Support:</b> We handle the final touches, ensuring everything is perfect. Plus, our support team is always available for any assistance you need post-installation.</li>
        </ul>
        <h3>Contact Us for the Best Modular Kitchen in Jaipur!</h3>
        <p>Transform your kitchen into a beautiful, functional space that meets your needs. Whether you’re interested in a modern modular kitchen or a luxury design, we offer a range of options tailored for every budget and style.</p>
        <p>Get in touch today for a consultation or visit our showroom in Jaipur to explore our designs in person. Let's create the kitchen of your dreams!</p>
      </div>
  </section>

      
    </>
  );
};

export default Home;
