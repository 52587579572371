// src/pages/Home.js
import React from "react";
import { ContactForm } from "../components/ContactForm";
import BookNow from "../components/BookNow";

const Contact = () => {
  return (
    <div>
      <section className="banner-section ">
        <img
          src="/images/about-banner.jpg"
          className="banner-img"
          alt="Banner Image"
        />
        <div className="container">
          <div className="banner-contant">
            <h1>Conatct Modular Kitchen</h1>
            <p class="mb-0">
            We transform your kitchen into a modern hub. We take pride in delivering on-time, hassle-free installation and exceptional customer service. Discover the perfect modular kitchen for your home, tailored to fit your needs and lifestyle.
            </p>
          </div>
        </div>
      </section>
      
      <section className="about-section">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-6 col-xl-5 heading-main mb-0 mb-md-4">
              <span className="badge rounded-pill text-bg-warning">
                About Us
              </span>
              <h2>We are the perfect team to bring your home kitchen decor dreams to life!</h2>
              <h3>
              We design experiences and craft products that drive business growth.
              </h3>
              <p>
               Receive expert guidance from modular kitchen, a seasoned business coach specializing in growth and scaling strategies.
              </p>

              <ul className="ulservices">
                <li>
                  <div className="uiInnner">
                    <strong>
                      <img src="images/Star.svg" alt="Business Services" />
                      100+
                    </strong>
                    <span>Interior Services</span>
                  </div>
                </li>
                <li>
                  <div className="uiInnner">
                    <strong>
                      <img src="images/happy.svg" alt="Happy Customer" />
                      800+
                    </strong>
                    <span>Happy Customer</span>
                  </div>
                </li>
              </ul>
            </div>

            <div className="col-lg-6">
              <img src="images/aboutus.png" alt="about image" />
            </div>
          </div>
        </div>
      </section>
      <BookNow />
      <section className="about-section">
        <div className="container">
          <div className="row justify-content-between align-items-start">
            <div className="col-lg-6 col-xl-5 heading-main">
              <span className="badge rounded-pill text-bg-warning">
                Proccess
              </span>
              <h2>Get in touch with us – it's easy!</h2>
              <p>
              You won't have to deal with such inconveniences because we have been diligently providing modular kitchen solutions in Jaipur
              </p>

              <ul className="Contact_process mt-4 pt-2">
                <li>
                  <div className="pro-flex">
                    <img src="images/pro-call.png" />
                    <p>
                      <span>Contact Number</span>
                      <strong>+91 78782 41849</strong>
                    </p>
                  </div>
                </li>

                <li>
                  <div className="pro-flex">
                    <img src="images/pro-email.png" />
                    <p>
                      <span>Official Email ID</span>
                      <strong>sales.modularkitchens@gmail.com</strong>
                    </p>
                  </div>
                </li>

                <li>
                  <div className="pro-flex">
                    <img src="images/pro-location.png" />
                    <p>
                      <span>Office Address</span>
                      <strong>
                      Metro Piller No 35, near Ganga Jamuna Petrol Pump, Gangaram Nagar, New Aatish Market, Shanthi Nagar, Mansarovar, Jaipur, Rajasthan 302020
                      </strong>
                    </p>
                  </div>
                </li>
              </ul>
            </div>

            <div className="col-lg-6">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
