// src/pages/Home.js
import React from "react";
import CompanyNumbers from "../components/CompanyNumber";
import { ContactForm } from "../components/ContactForm";
import BookNow from "../components/BookNow";
const About = () => {
  return (
    <>
      <section className="banner-section ">
        <img
          src="/images/about-banner.jpg"
          className="banner-img"
          alt="Banner Image"
        />
        <div className="container">
          <div className="banner-contant">
            <h1>About Modular Kitchen</h1>
            <p class="mb-0">
            At Modular Kitchen Jaipur, we specialize in creating stylish and functional kitchen spaces that blend seamlessly with your home’s aesthetic. Our expert designers offer customized solutions, ensuring your kitchen is both practical and visually stunning. With top-quality materials and innovative designs, we transform your kitchen into a modern hub.
            </p>
          </div>
        </div>
      </section>
      <section className="about-section">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-6 col-xl-5 heading-main mb-0 mb-md-4">
              <span className="badge rounded-pill text-bg-warning">
                About Us
              </span>
              <h2>We are the perfect team to bring your home kitchen decor dreams to life!</h2>
              <h3>
              We design experiences and craft products that drive business growth.
              </h3>
              <p>
               Receive expert guidance from modular kitchen, a seasoned business coach specializing in growth and scaling strategies.
              </p>

              <ul className="ulservices">
                <li>
                  <div className="uiInnner">
                    <strong>
                      <img src="images/Star.svg" alt="Business Services" />
                      100+
                    </strong>
                    <span>Interior Services</span>
                  </div>
                </li>
                <li>
                  <div className="uiInnner">
                    <strong>
                      <img src="images/happy.svg" alt="Happy Customer" />
                      800+
                    </strong>
                    <span>Happy Customer</span>
                  </div>
                </li>
              </ul>
            </div>

            <div className="col-lg-6">
              <img src="images/aboutus.png" alt="about image" />
            </div>
          </div>
        </div>
      </section>
      <CompanyNumbers />

      {/* <section className="ourTeams mt-5 pb-4">
        <div className="container">
          <div className="heading-main mb-5">
            <span className="badge rounded-pill text-bg-warning">
            Our team
            </span>
            <h2 className="d-flex align-items-end">
              Inspiration for home <br />
              interior designs
            </h2>
          </div>
          <div class="row justify-content-center">
             <div class="col-md-4 mb-5">
                <div class="team-box">
                    <figure>
                        <img src="images/team-1.png" alt="team" />
                        <span class="badge">Founder at Company</span>
                    </figure>
                    <h3>Rs Rathore</h3>
                </div>
             </div>
             <div class="col-md-4 mb-5">
                <div class="team-box">
                    <figure>
                        <img src="images/team-2.png" alt="team" />
                        <span class="badge bg-team-badge">Design Head & Project Head</span>
                    </figure>
                    <h3>Muskan</h3>
                </div>
             </div>
             <div class="col-md-4 mb-5">
                <div class="team-box">
                    <figure>
                        <img src="images/team-3.png" alt="team" />
                        <span class="badge bg-team-badge">Interior Designer</span>
                    </figure>
                    <h3>Manshi</h3>
                </div>
             </div>
             <div class="col-md-4 mb-5">
                <div class="team-box">
                    <figure>
                        <img src="images/sher-singh.png" alt="team" />
                        <span class="badge bg-team-badge">Site Manager</span>
                    </figure>
                    <h3>Sher Singh</h3>
                </div>
             </div>
             <div class="col-md-4 mb-5">
                <div class="team-box">
                    <figure>
                        <img src="images/team-5.png" alt="team" />
                        <span class="badge bg-team-badge">Site Manager</span>
                    </figure>
                    <h3>Neerwardhan Singh</h3>
                </div>
             </div>
             <div class="col-md-4 mb-5">
                <div class="team-box">
                    <figure>
                        <img src="images/team-6.png" alt="team" />
                        <span class="badge bg-team-badge">Head of Department (Carpentry)</span>
                    </figure>
                    <h3>Sahid Tyagi</h3>
                </div>
             </div>
          </div>
        </div>
      </section> */}


      <BookNow />

      <section className="about-section pt-4">
      <div className="container">
          <div className="row justify-content-between align-items-start">
            <div className="col-lg-6 col-xl-5 heading-main">
              <span className="badge rounded-pill text-bg-warning">
                Proccess
              </span>
              <h2>Get in touch with us – it's easy!</h2>
              <p>
              You won't have to deal with such inconveniences because we have been diligently providing modular kitchen solutions in Jaipur.
              </p>

              <ul className="Contact_process mt-4 pt-2">
                <li>
                  <div className="pro-flex">
                    <img src="images/pro-call.png" />
                    <p>
                      <span>Contact Number</span>
                      <strong>+91 78782 41849</strong>
                    </p>
                  </div>
                </li>

                <li>
                  <div className="pro-flex">
                    <img src="images/pro-email.png" />
                    <p>
                      <span>Official Email ID</span>
                      <strong>sales.modularkitchens@gmail.com</strong>
                    </p>
                  </div>
                </li>

                <li>
                  <div className="pro-flex">
                    <img src="images/pro-location.png" />
                    <p>
                      <span>Office Address</span>
                      <strong>
                      Metro Piller No 35, near Ganga Jamuna Petrol Pump, Gangaram Nagar, New Aatish Market, Shanthi Nagar, Mansarovar, Jaipur, Rajasthan 302020
                      </strong>
                    </p>
                  </div>
                </li>
              </ul>
            </div>

            <div className="col-lg-6">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
      <section className="seoText">
      <div className="container">
         <p>Welcome to Modular kitchen – Jaipur’s premier destination for innovative, stylish, and functional modular kitchens. We are passionate about creating kitchens that go beyond the ordinary, combining cutting-edge designs with high-quality materials to build spaces where functionality meets elegance. Our commitment to excellence, coupled with our focus on customer satisfaction, has established us as a trusted name in the modular kitchen industry in Jaipur.</p>
        <h3>Our Story</h3>
        <p>Founded on the belief that the kitchen is the heart of the home, Modular kitchen started as a small team with a vision to redefine the kitchen experience. Over the years, we have grown into a team of seasoned designers, craftsmen, and installation experts who are dedicated to transforming kitchens across Jaipur. With each project, we aim to deliver kitchens that not only look beautiful but also elevate the daily cooking and dining experience.</p>
        <h3>Our Mission</h3>
        <p>Our mission is to bring world-class modular kitchens to every home, with designs that reflect individual style and the highest standards of functionality. We strive to make premium-quality modular kitchens accessible and affordable for all, empowering homeowners to enjoy spaces that inspire culinary creativity and comfortable living.</p>
        <h3>What Sets Us Apart?</h3>
        <ul>
          <li><b>●	Customized Designs: </b>No two kitchens are the same. We create personalized designs that reflect your unique taste and meet your specific requirements.</li>
          <li><b>●	Quality Craftsmanship:</b> Every element, from cabinetry to countertops, is crafted with attention to detail and built to withstand the demands of a busy kitchen.</li>
          <li><b>●	Experienced Team:</b> Our team of designers and installers has years of experience and a passion for delivering kitchens that exceed expectations.</li>
          <li><b>●	Comprehensive Services:</b> From initial design consultations to installation and post-service support, we are with you every step of the way.</li>
          <li><b>●	Innovation and Trends:</b> We stay ahead of trends and bring the latest in kitchen technology and materials to ensure your kitchen is modern, efficient, and timeless.</li>
        </ul>
        <h3>Our Process</h3>
        <p>Our approach is simple, transparent, and customer-centric. We begin by understanding your vision, space, and budget. Then, we work closely with you to select materials, finishes, and layouts that match your style. Once the design is finalized, our expert team ensures that installation is quick, precise, and hassle-free.</p>
        <h3>Our Promise</h3>
        <p>At Modular kitchen, we are committed to excellence in every project. We believe in delivering kitchens that not only meet but exceed expectations. From sleek, modern designs to luxurious custom kitchens, we promise quality, durability, and exceptional service with each kitchen we create.</p>
        <h3>Get in Touch</h3>
        <p>Ready to transform your kitchen? Whether you're looking for a modern upgrade or a complete kitchen remodel, we are here to bring your dream kitchen to life. Contact us today to schedule a consultation or visit our showroom in Jaipur to explore our designs. Let’s create the kitchen of your dreams!</p>
      </div>
      </section>

    </>
  );
};

export default About;
